/* :root {
  --primary: var(--primary);
  --error: #e7195a;
} */

.calendar-details {
  background: #fff;
  border-radius: 4px;
  margin: 20px auto;
  padding: 20px;
  position: relative;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.05);
}

.fc .fc-button-primary {
  color: #fff;
  color: var(--fc-button-text-color, #fff);
  background-color: var(--primary);
  border-color: #fff;
  text-transform: uppercase;
}

.fc .fc-button-primary:not(:disabled).fc-button-active {
  color: #fff;
  color: var(--fc-button-text-color, #fff);
  background-color: #1a252f;
  border-color: #fff;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: rgb(17, 105, 167, .15);

}

.fc .fc-timegrid-col.fc-day-today {
  background-color: #fff;
}

/* Home Calendar Display */
.home-calendar-details {
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  position: relative;
  box-shadow: 2px 2px 5px rgba(17, 105, 167, 0.05);
}

.home-calendar-details .fc-toolbar-title {
  font-size: 24px;
}

/* small forms */
.multi-label-group {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 15px 0 15px 0;
  column-gap: 25px;
}

.single-label-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.single-label-group>label,
.multi-label-group>div>label {
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.2rem;
  text-align: center;
  margin-bottom: 15px
}

.single-label-group>input,
.multi-label-group>div>input {
  margin-top: 5px;
  margin-bottom: 7px;
  border: 1px solid var(--primary);
  font-family: "Poppins";
  font-size: 16px;
  padding: 5px;
}

.single-label-group>input:focus,
.multi-label-group>div>input:focus {
  outline: none;
  border: 1px solid var(--primary);
  box-shadow: inset 0 0 5px var(--primary);
}

.popupHidden {
  display: none;
}

.popupActive {
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.popupActive>div {
  background-color: white;
  border-radius: 15px;
  border: var(--primary) 1px solid;
  padding: 50px;
}

.popupActive>div {
  position: relative;
}

.closePopup {
  position: absolute;
  /* top: 0;
  right: 0; */
  top: 5%;
  right: 5%;
  cursor: pointer;
}

.delete {
  cursor: pointer;
  margin-right: 10px;
}

.editForm {
  border: none;
}

.material-symbols-outlined {
  background-color: var(--primary);
  padding: 5px;
  font-size: 16px;
  color: white;
  /* margin-bottom: 20px; */
  border-radius: 50%;
}

.cal-popup-edit {
  display: flex;
  margin-top: 50px;
}

material-symbols-outlined delete {
  margin-right: 25px;
}

.mobileCal {
  display: none;
}

/* .single-label-group>textarea {
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.2rem;
  background: #F5F5F5;
  border: 1px solid #3B517A;
  box-shadow: inset 1px 1px 5px rgba(59, 81, 122, 0.5);
  border-radius: 5px; */

/* Tooltip styles */

.popper,
.tooltip {
  position: absolute;
  z-index: 9999;
  background: #1169A7;
  color: white;
  width: 300px;
  border-radius: 3px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  padding: 10px;
  font-size: 16px;
  text-align: center;
}

/* .style5 .tooltip {
  background: #1E252B;
  color: #FFFFFF;
  max-width: 200px;
  width: auto;
  font-size: .8rem;
  padding: .5em 1em;
} */

.popper .popper__arrow,
.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
}

.tooltip .tooltip-arrow,
.popper .popper__arrow {
  border-color: #1169A7;
}

.style5 .tooltip .tooltip-arrow {
  border-color: #1E252B;
}

.popper,
.tooltip {
  margin-bottom: 5px;
}

.popper .popper__arrow,
.tooltip .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}
@media only screen and (max-width: 600px) {

    /* home */
    .home {
        grid-template-columns: 1fr;
        width: 100%;
        gap: 30px;
    }

    .homeBtnGroup {
        width: 100%;
        align-items: center;
    }

    .homeBtnGroup>.button {
        min-width: 50%;
    }

    .home-calendar-details {
        max-width: 100%;
    }

    /* navbar */
    .container {
        display: flex;
        flex-direction: column;
    }

    .nav-contain {
        flex-direction: column;
    }

    .nav-menu-container {
        flex-wrap: wrap;
        margin-bottom: 10px;
    }

    /* calendar */
    .desktopCal {
        display: none !important;
    }

    .mobileCal {
        display: block !important;
    }

    .home-calendar-details .fc-toolbar-title {
        font-size: 18px !important;
        margin-left: 5px !important;
        text-align: center !important;
    }

    /*  */
    .note-header {
        width: 100%;
        margin-bottom: 15px;
    }

    .note-header>h1 {
        font-size: 18px;
    }

    .note-header>.button {
        width: 40%;
        font-size: 15px;
    }

    /* vendor */
    .vendor-grid {
        grid-template-columns: 1fr;
        width: 100%;
        gap: 0;
    }

    /* clients */
    .client-box {
        width: 100%;
    }

    .clients-in-grid {
        width: 85%;
        margin: 20px auto 20px auto;
    }

    .client-col-left,
    .client-item-layout {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }

    /* add forms */
    .add-property-wrapper {
        grid-template-columns: 1fr;
        width: 80%;
        margin: auto;
    }

    .new-property-grid {
        grid-template-columns: 1fr;
    }

    .new-property-grid-col {
        width: 70vw;
    }

    .new-prop-input-2col {
        flex-direction: column;
    }

    .new-prop-input-2col>div,
    .new-prop-input-2col>div>input,
    .new-prop-input-2col>div>label,
    .new-property-grid-col>div>input,
    .new-property-grid-col>ul {
        width: 98%;
    }

    /* single prop */
    .property-wrapper {
        grid-template-columns: 1fr;
    }

    .contactInfo>p {
        font-size: 15px;
    }

    /* single vendor */
    .client-contact {
        flex-direction: column;
        row-gap: 20px;
    }
}
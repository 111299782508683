/* google font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&family=VT323&display=swap');

@import url('https://cdn.syncfusion.com/ej2/material.css');

/* @tailwind base;
@tailwind components;
@tailwind utilities; */

/* layout */
:root {
  --primary: #1169A7;
  --error: #e7195a;
}

body {
  background: #f1f1f1;
  margin: 0;
  font-family: "Poppins";
}

header {
  background: #fff;
}

header .container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

header a {
  color: #333;
  text-decoration: none;
}

.logo {
  max-width: 250px;
}

.pages {
  max-width: 1400px;
  padding: 20px;
  margin: 0 auto;
}

/* Common styles */
.more-info {
  font-size: 16px;
  background-color: var(--primary);
  color: #fff;
  border-radius: 5px;
  border-color: #fff;
  padding: 5px 10px;
  text-decoration: none;
  cursor: pointer;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.05);
}

.trash-icon {
  font-size: 20px;
  background-color: var(--primary);
  color: #fff;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.05);
}

button,
.button {
  background: var(--primary);
  border: 0;
  color: #fff;
  padding: 5px 10px;
  font-family: "Poppins";
  border-radius: 4px;
  cursor: pointer;
}

.button {
  max-width: 50%;
  text-align: center;
  text-decoration: none;
}

/* homepage */
.home {
  display: grid;
  grid-template-columns: 1.25fr .75fr;
  gap: 100px;
}

.owner-details {
  display: flex;
  background: #fff;
  border-radius: 15px;
  margin-bottom: 40px;
  padding: 20px;
  position: relative;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.05);
}

.owner-details h4 {
  margin: 0 0 10px 0;
  font-size: 1.2em;
  color: var(--primary);
}

.owner-details p {
  margin: 0;
  font-size: 0.9em;
  color: #555;
}

.owner-details span {
  top: 20px;
  right: 20px;
  cursor: pointer;
  padding: 6px;
  border-radius: 5;
  color: #333;
}

.single-layout {
  /* border: 1px solid var(--error); */
  background: #fff;
  border-radius: 15px;
  margin-top: 80px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  position: relative;
  max-width: 1200px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.05);
}

/* new owner form */
/* label,
input {
  display: block;
} */

.login {
  background: #fff;
  border-radius: 30px;
  margin-top: 150px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  position: relative;
  max-width: 1200px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.05);
}

.login input {
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.input-space {
  width: 80%;
}

form button {
  background: var(--primary);
  border: 0;
  color: #fff;
  padding: 10px;
  font-family: "Poppins";
  border-radius: 4px;
  cursor: pointer;
}

div.error {
  padding: 10px;
  background: #ffefef;
  border: 1px solid var(--error);
  color: var(--error);
  border-radius: 4px;
  margin: 20px 0;
}

div.success {
  padding: 10px;
  background: #f2ffef;
  border: 1px solid var(--primary);
  color: var(--primary);
  border-radius: 4px;
  margin: 20px 0;
}



/* navbar */
nav {
  display: flex;
  align-items: center;
  width: 80%;
  /* border: 1px solid var(--error); */
}

nav a {
  margin-left: 10px;
}

.nav-contain {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-left: 20%;
}

.nav-menu-container {
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: space-between;
  /* border: 1px solid var(--error); */
}

nav button {
  background: #fff;
  color: var(--primary);
  border: 2px solid var(--primary);
  padding: 6px 10px;
  border-radius: 4px;
  font-family: "Poppins";
  cursor: pointer;
  font-size: 1em;
  margin-left: 10px;
}

/* auth forms */
form.signup,
form.login {
  max-width: 400px;
  margin: 40px auto;
  padding: 20px;
  background: #fff;
  border-radius: 15px;
}

/* Calendar styles */
.cal-wrapper {
  margin: 2rem;
  margin-top: 24px;
  padding: 2rem;
  background-color: white;
  border-radius: 50px;
}

/* Client List styles */
.client-wrapper {
  /* border: 1px solid var(--error); */
  display: flex;
}

.client-item-layout {
  /* border: 1px solid var(--error); */
  width: 100%;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
}

.client-col-left {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  /* border: 1px solid var(--error); */
  width: 100%;
}

.client-col-left p,
.client-item-col-left p,
.contact-info-block {
  font-size: 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}



.note-header {
  display: flex;
  align-items: center;
  gap: 50px;
}

.note-wrapper {
  position: relative;
}

.note-wrapper>.notes {
  position: absolute;
  background-color: #fff;
  width: 50vw;
  min-width: 500px;
  left: -150px;
  top: 20px;
}

.notes {
  border: var(--primary) 1px solid;
  border-radius: 15px;
  margin-bottom: 15px;
  padding: 0 20px;
}

.notes .text-sm {
  font-size: 14px;
}

.client-col-right {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: baseline;
  /* border: 1px solid var(--error); */
}

.client-item-col-right {
  /* border: 1px solid var(--error); */
  /* margin-left: 50px; */
  width: 50%;
}

.client-item-col-far-right {
  /* border: 1px solid var(--error); */
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.client-property-layout {
  margin-top: 40px;
}

.client-item-col-left h4 {
  font-size: 24px;
  color: var(--primary);
  font-weight: 500;
  margin: 0;

}

.client-property-single {
  margin-bottom: 40px;
}

.client-contact {
  display: flex;
  margin-top: 40px;
}

.client-contact strong {
  color: var(--primary);
  font-size: 22px;
  font-weight: 500;
}

.client-contact-left {
  margin-right: 100px;
}

.client-properties {
  border: var(--primary) 1px solid;
  border-radius: 15px;
  margin-right: 30px;
  margin-bottom: 15px;
  padding: 20px 40px;
}

.client-properties .client-prop-title {
  color: var(--primary);
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;

}

.client-properties p {
  margin: 0;
}

.client-properties button {
  margin-top: 15px;
}

/* Property List Style */
.client-grid {
  /* border: 1px solid var(--error); */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1200px;
  width: 100%;
  margin: auto;
}

.vendor-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  max-width: 1200px;
  width: 100%;
  margin: auto;
}

.vendor-link-wrapper {
  width: 100%;
  display: flex;
}

.prop-grid {
  /* border: 1px solid var(--error); */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1000px;
  width: 100%;
  margin: auto;
}

.props-in-grid {
  border-radius: 15px;
  max-width: 400px;
  width: 100%
}

.clients-in-grid {
  border-radius: 15px;
  width: 60%;
}

.prop-buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

}

/* Single Property Style */
.property-wrapper {
  /* border: 1px solid var(--error); */
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: 50px;
  width: 100%;
  max-width: 1200px;
  background-color: white;
  margin-top: 40px;
  margin-bottom: 40px;
  position: relative;
}

.add-property-wrapper {
  /* border: 1px solid var(--error); */
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: 50px;
  width: 100%;
  max-width: 1200px;
  background-color: white;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding: 30px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
}

.property-col-left h4 {
  font-size: 24px;
  color: var(--primary);
  font-weight: 700;
  margin: 0;
}

.property-col-left h5 {
  font-size: 22px;
  color: var(--primary);
  font-weight: 600;
  margin: 0;
}

.property-col-left p {
  font-size: 18px;
  margin: 0;
}

.property-button-row {
  display: flex;
}

.property-button-row button {
  margin-right: 20px;
  margin-top: 20px;
}

.property-col-right h4 {
  font-size: 24px;
  color: var(--primary);
  font-weight: 700;
  margin: 0;
}

.property-col-right h5 {
  font-size: 22px;
  color: var(--primary);
  font-weight: 600;
  margin: 0;
}

.property-col-right p {
  font-size: 18px;
  margin: 0;
}

.property-features {
  margin-top: 80px;
}

.owner-features {
  margin-top: 40px;
}

.property-features-grid {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  column-gap: 20px;
  width: 100%;
  max-width: 500px;
}

.property-events {
  display: flex;
  width: 100%;
  max-width: 500px;
  justify-content: space-between;
  margin-bottom: 10px;
}

.property-image {
  width: 100%;
  object-fit: cover;
}

#myInput {
  font-size: 16px;
  /* Increase font-size */
  padding: 5px;
  /* Add some padding */
  border: 1px solid rgba(17, 105, 167, .5);
  /* Add a blue border */
  margin-bottom: 12px;
  /* Add some space below the input */
  border-radius: 4px;
}

#myInput:focus {
  outline: 1px solid rgba(17, 105, 167, .8);
}

#myUL {
  /* Remove default list styling */
  list-style-type: none;
  padding: 0;
  margin: 0;
}

#myUL li {
  display: none;
}

#myUL li p {
  /* border: 1px solid #ddd; */
  /* Add a border to all links */
  /* margin-top: -1px; */
  /* Prevent double borders */
  /* background-color: #f6f6f6; */
  /* Grey background color */
  /* padding: 12px; */
  /* Add some padding */
  font-size: 18px;
  /* Increase the font-size */
  color: black;
  /* Add a black text color */
  display: block;
  /* Make it into a block element to fill the whole list */
}

/* New Property Style */
.new-property-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 150px;
  width: 100%;
}

#clientForm>.new-property-grid {
  grid-template-columns: 1fr 1fr;
}

.new-property-grid-col {
  display: flex;
  flex-direction: column;
  width: 300px;
}

.new-prop-input-2col {
  display: flex;
}

.row-2 {
  margin-top: 40px;
}

.new-prop-label-title {
  font-size: 18px;
  font-weight: 600;
  color: var(--primary);
  margin-bottom: 5px;
}

p.new-prop-label-title {
  margin-bottom: 5px;
  margin-top: 5px;
}

.feature {
  margin-bottom: 12px;
}

.new-property-grid label,
.conference-form label {
  margin-top: 0px;
}

.new-property-grid input,
.conference-form input {
  margin-top: 5px;
  margin-bottom: 7px;
  border: 1px solid var(--primary);
  font-family: "Poppins";
  font-size: 16px;
  padding: 5px;
}

.new-property-grid input:focus,
.conference-form input:focus {
  outline: none;
  border: 1px solid var(--primary);
  box-shadow: inset 0 0 5px var(--primary);
}

.new-prop-select {
  border-color: var(--primary);
  font-family: "Poppins";
  font-size: 16px;
  padding: 5px 0;
  margin-right: 10px;
}

.new-prop-select:focus {
  outline: none;
  border: 1px solid var(--primary);
  box-shadow: inset 0 0 5px var(--primary);
}

.add-button {
  /* position: absolute; */
  color: var(--primary);
  font-size: 25px;
  /* bottom: 30px; */
  cursor: pointer;
}

.add-button-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.add-button-wrapper>p {
  padding-left: 10px;
  margin: 0;
  font-size: 18px;
  color: var(--primary);
  padding-bottom: 10px;
}

.homeBtnGroup {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.event-popup-wrapper {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
}

.popup {
  background-color: white;
  border-radius: 15px;
  border: var(--primary) 1px solid;
  padding: 25px;
}

.popup>h2 {
  color: var(--primary);
}

.popup-button-group {
  width: 100%;
  display: flex;
  margin-top: 20px;
}

.popup-button-group button {
  margin-right: 15px;
  margin-bottom: 10px;

}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* property features */
.featurePopUp {
  display: fixed;
  top: 15%;
  background-color: white;
  border-radius: 15px;
  border: #1aac83 1px solid;
  padding: 25px;
}

.feature-section-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.feature-section-wrapper>.current-feature-wrapper {
  width: 60%;
}

.feature-section-wrapper>.current-feature-wrapper>ul {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.feature-section-wrapper>.current-feature-wrapper>ul>li>div {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 20px;
  margin-bottom: 15px;
}

.feature-section-wrapper>.current-feature-wrapper>ul>li>div>.icon {
  height: 22px;
  padding: 0;
  margin-left: 15px;
}

.feature-section-wrapper>.current-feature-wrapper>ul>li>div>.icon>.trash-icon {
  font-size: 12px;
}

#featureForm>div {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}